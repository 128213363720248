<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <path
      d="M0.911133 3.00794C3.96028 2.75308 5.73399 2.74394 8.94999 3.00794"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.93066 0.997681V2.70625"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.66895 3.00806C2.66895 4.78177 4.1158 6.33034 6.19923 7.71663"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.19099 3.00806C7.24128 5.18063 5.02071 7.01606 2.16699 8.62748"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.7627 15.0491L10.4313 7.04342C10.4982 6.8433 10.6263 6.66929 10.7976 6.54602C10.9688 6.42275 11.1745 6.35647 11.3856 6.35657C11.5962 6.35663 11.8016 6.42285 11.9726 6.54589C12.1436 6.66892 12.2717 6.84256 12.3387 7.04228L15.0073 15.0491"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.72949 12.152H14.0415"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
